export const mainData = {
    content: {
        home: [
            {
                title: "Glocalized Education For The New Education",
                image: "./header-img/about-info.jpg",
                description: [
                    "Here at Dr. Yanga's Colleges Inc., we are committed to provide education that would equip our students to become globally competitive and at the same time, locally engaged with what's happening in our society. And in order to realize this mission, we give importance in delivering a 'glocalized education' that suits the modern teaching-learning needs of each and every DYCIan.",
                    "Global plus local, hence, Glocal!"
                ],
                figures: [
                    {
                        label: "Years of Academic Excellence",
                        figure: "75",
                    },
                    {
                        label: "Academic Programs",
                        figure: "20+"
                    },
                    {
                        label: "National Competition Titles",
                        figure: "50+"
                    },
                    {
                        label: "International Competitions Participation",
                        figure: "15+"
                    }
                ],
            }
        ],
        admissions: [
            {
                title: "Discover your future with us today!",
                description: "At DYCI, we are dedicated to nurturing the next generation of leaders and innovators. Our admissions proccess is designed to identify passionated and motivated individuals who are eager to join our vibrant academic community. We offer a range of undergraduate and graduate programs that cater to diverse interests and career aspirations.",
            }
        ],
        scholarships: [
            {
                subtitle: "Scholarships",
                title: "Scholarships At DYCI",
                image: "./images/scholarships.jpg",
                description: "Dr. Yanga Basic Education is committed in making quality education more affordable by granting scholarships to financially-challeged but capable students. Tuition discounts are also given to reward parents, guardians and even students with their loyalty and prompt actions with regards to their financial obligations.",
                link: "/scholarships"
            }
        ],
        academics: [
            {
                title: "Glocalized Education",
                description: "At DYCI, we develop morally grounded, globally competitive leaders through innovative education and a strong focus on character formation. Join us in a community that values excellence, service, and positive societal impact. By integrating international perspectives with a deep understanding of local culture and needs, we empower students to think globally while acting locally.",
            }
        ],
        basicEducation: [
            {
                title: "Basic Education",
                description: "At DYCI, the Basic Education Department serves as the foundation for shaping morally grounded, globally competitive leaders. Through innovative and student-centered education, we cultivate academic excellence, critical thinking, and strong character formation, preparing learners to thrive in a rapidly changing world.",
                departments: [
                    {
                        title: "Pre-School and Grade School",
                        link: "/gs"
                    },
                    {
                        title: "Junior High School",
                        link: "/jhs"
                    },
                    {
                        title: "Senior High School",
                        link: "/shs",
                    },
                    // {
                    //     title: "College",
                    //     link: "/college"
                    // }
                ]
            }
        ],
        higherEducation: [
            {
                title: "Higher Education",
                description: "DYCI Higher Education molds graduates to excel professionally and contribute meaningfully to their communities, reflecting the institution’s mission of academic excellence and societal impact.",
                departments: [
                    {
                        title: "College",
                        link: "/college"
                    }
                ]
            }
        ],
        about: [
            {
                title: "About DYCI",
                description: [
                    "The culture of doing more for others and the passion for excellence have always been impregnable anchors of the Dr. Yanga’s Colleges, Inc. (DYCI) as an institution – from the students, faculty, administration and the entire school as well. Aside from the achievements, awards and recognitions that the school, the administration, the faculty and the students have collectively reaped through the years, DYCI High School has also put a premium in doing social responsibility, involving every DYCIan to do good and help others.",
                    "The societal role of DYCIans is fully anchored on the need to inscribe the presence of God to the whole community and the values essential to nation-building, especially catering to the youth and the impoverished.",
                    "Participation of DYCIans in community-building go beyond the traditional outreach programs and even involve itself in the arts and culture. Since 2004, DYCIans are participating in the reenactment of Rizal execution every December 30. The DYCI Dancetroupe and choir members have alse been part of many civic activities that deepen the appreciation of arts, history and culture.",
                    "Indeed, in our own ways, far more than the basic function of an academe, I can proudly say that Dr. Yanga’s Colleges, Inc. provides good service to its multi-stakeholders."
                ],
                dataPrivacy: {
                    title: "Data Privacy Policy and Consent of Website Users, Associated Portals and Online Platform of Dr. Yanga's Colleges, Inc.",
                    information: [
                        {
                            title: "Consent",
                            description: "By continuing to use the DYCI website and its related portals, you confirm your understanding and agreement with this policy. You also acknowledge that you can withdraw consent at any time, subject to legal and institutional requirements."
                        },
                        {
                            title: "Contact Information",
                            description: "For any questions or concerns regarding your personal data, please contact:",
                            contact: [
                                {
                                    department: "Information Technology Systems Service",
                                    email: "itss@dyci.edu.ph",
                                    contact: "",
                                    website: ""
                                }, 
                                {
                                    department: "Data-driven Excellence through Value-anchored and Effective Learning and Organizational Performance (DEVELOP) Office",
                                    email: "develop@dyci.edu.ph",
                                    contact: "",
                                    website: "https://dyci.edu.ph"
                                },
                            ]
                        },
                        {
                            title: "",
                            description: ""
                        }
                    ]
                },
            }
        ],
        vision: [
            {
                title: "Our Vision",
                description: "Illumined by the spirit of being magis quam schola, familia – more than a school, a family – Dr. Yanga's Colleges, Inc. envisions to be God-Centered and glocally excellent beacon of moral formation, holistic learning, transformative research and innovations, and sustainable societal communion for all humanity.",
            },
        ],
        mission: [
            {
                title: "Our Mission",
                description: "DYCI is committed to achieve its vision through this three-fold mission:",
                list: [
                    {
                        list1: "To edify our learners and stakeholders through God-Centered and holistic formation of the heart, mind, and soul.",
                        list2: "To transform our learners through glocalized education and culture, fusing global competencies with local rootedness and social responsibility.",
                        list3: "To instill every DYCIan the call to lead, to serve and to commune with our society through sustainable and transformative pursuits."
                    }
                ]
            }
        ],
        coreValues: [
            {
                title: "Our Core Values",
                description: "",
                list: [
                    {
                        title: "God-Centeredness",
                        description: "The philoshopical bedrock of DYCI's core values. God-centeredness is an English term signifying a steadfast commitment to align one's actions, words, and thoughts to glorify God, while respecting individuals across every faith, mission, and identity. It is the fundamental principle that DYCIans strive to live by through the realization of the following core values: Magis, Sapentia, and Paraya."
                    },
                    {
                        title: "Magis",
                        description: "The Latin for 'more'. This word embodies the spirit of aspiring for more, for the common good, and for the greater glory of God. DYCIans embrace this value when they creatively and innovately pursue growth, constantly refining their skills, expanding their knowledge, and cultivating virtues to exceed their personal bests in order to honor God through their service to humanity."
                    },
                    {
                        title: "Paraya",
                        description: "A Filipino term that signifies the sincere gift of the self. DYCIans embody this value when they participate in sustainable actions that uplift their neighbors' lives by demonstrating stewardship, empathy, and social responsibility. This is also a coined term of DYCI's heart for the community (PA-mayaman), the school (paa-RA-lan), and the parish (parok-YA)."
                    },
                    {
                        title: "Sapientia",
                        description: "The latin for 'wisdom'. It symbolized the gentle wisdom conveyed from a lifelong pursuit of both local and global knowledge and truth. DYCIans embody this value when they strenghten their relationships with others through their knowlegde as an act of caring for the whole person."
                    }
                ]
            }
        ],
        seal: [
            {
                title: "The School Seal",
                src: "./dyci-logo.png",
                description: [
                    {   
                    // "The school seal symbolizes the embodiment of the academic commitments and all the servixes of Dr. Yanga's Colleges, Inc. Founded in 1950 as a private non-sectarian secondary school named Francisco Balagtas Academy.",
                    // "The outer circle holds the name of the school with blue as its background. The inner circle bears against a gold background, images that illustrate the institution's scholastic dedication to its community.",
                    // "The open book and quill on top represent the College of Arts and Sciences with its goal to propagate knowledge, determination, and academic accountability. Embedded on a white background, the nine stars that surrounds its founding year represent the first nine offerings of the institution namely Education, Midwifery, Secretarial, Veterinary, Medicine, Agriculture, Nursing, Commerce, Seaman Training Course, and Vocational Course.",
                    // "On the lower left part is the caduceus signifying the allied health care courses that aim to educate students with relevant information about the practice ogf health and its underlying principles and ethics. The torch on the lower right part reflects the unending aspiration towards the realization of the school's mission and vision to mold our students to bring positive impact in the quality of life of other people.",
                    // "The College colors are blue, gold, and white. Blue represents inspiration, sincerity, and spirituality. Gold denotes wisdom, excellence, and success. White stands for purity, precision, and truthfulness."
                        title: "The School Seal",
                        description: "The school seal symbolizes the embodiment of the academic commitments and all the servixes of Dr. Yanga's Colleges, Inc. Founded in 1950 as a private non-sectarian secondary school named Francisco Balagtas Academy."
                    },
                    {
                        title: "Outer Circle",
                        description: "The outer circle holds the name of the school with blue as its background. The inner circle bears against a gold background, images that illustrate the institution's scholastic dedication to its community"
                    },
                    {
                        title: "Open Book and Quill",
                        description: "The open book and quill on top represent the College of Arts and Sciences with its goal to propagate knowledge, determination, and academic accountability. Embedded on a white background, the nine stars that surrounds its founding year represent the first nine offerings of the institution namely Education, Midwifery, Secretarial, Veterinary, Medicine, Agriculture, Nursing, Commerce, Seaman Training Course, and Vocational Course."
                    },
                    {
                        title: "Caduceus and Torch",
                        description: "On the lower left part is the caduceus signifying the allied health care courses that aim to educate students with relevant information about the practice ogf health and its underlying principles and ethics. The torch on the lower right part reflects the unending aspiration towards the realization of the school's mission and vision to mold our students to bring positive impact in the quality of life of other people."
                    },
                    {
                        title: "College Colors",
                        description: "The College colors are blue, gold, and white. Blue represents inspiration, sincerity, and spirituality. Gold denotes wisdom, excellence, and success. White stands for purity, precision, and truthfulness."
                    },
                ]
                
            }
        ],
        history: [
            {
                title: "Our History",
                description: "Our restless drive in transforming every DYCIan to a global God-Centered heroic leader in heart, mind, and soul.",
                years: [
                    {
                        year: "1950s - 1960s",
                        subtitle: "Foundation and Growth",
                        description: [
                            "In the vibrant landscape of the 1950s, a new chapter in education unfolded as Francisco Balagtas Academy (FBA) emerged, a beacon of learning established by the visionary couple, Mr. and Mrs. Gerardo Samson Sr. The academy's corridors echoed with the footsteps of curious minds, laying the groundwork for what was to become an illustrious journey. For nearly four decades, from 1950 to 1987, the institution steadfastly bore the name Francisco Balagtas Academy (FBA), nurturing generations of young learners in its embrace. The year 1960 marked an official milestone as FBA was officially registered with the prestigious Securities and Exchange Commission, solidifying its commitment to education."
                        ]
                    },
                    {
                        year: "1970s - 1980s",
                        subtitle: "Expanding Programs and Leadership",
                        description: [
                            "As the 1970s dawned, the academy found itself under the stewardship of two remarkable individuals, Dr. Ismael E. Yanga, Sr. and Mrs. Sofia D. Yanga. During their tenure from 1972 to 1974, a pivotal moment was reached when they assumed the helm of Dr. Yanga's School of Midwifery. This heralded a significant turning point, as Government Recognition for Midwifery was granted, and the first class of Midwives proudly stepped into the world in 1975.",
                            "In 1974, a new era commenced as Dr. Ismael E. Yanga, Sr. and Mrs. Sofia D. Yanga orchestrated the acquisition of FBA, marking the institution with their indelible mark. With their dedication and commitment, the academy's name was transformed into Dr. Yanga's Francisco Balagtas Colleges (DYFBC) in 1987. The journey towards growth and excellence continued, with Government Recognition attained in 1976 for the complete operation of the Secondary Level. By 1977, DYFBC's horizons expanded further, with the introduction of Tertiary Degree Programs, a testament to the institution's forward-looking spirit. From Midwifery and Secretarial Education to Veterinary Medicine, Agriculture, Nursing, Commerce, and Seaman Training, the institution's offerings blossomed."
                        ]
                    },
                    {
                        year: "1980s - 2010s",
                        subtitle: "Expansion and Accreditation",
                        description: [
                            "The vibrant 1980s brought winds of change and growth. In 1989, Government Recognition for the Nursing Degree Program was secured, a vital step towards nurturing the healthcare professionals of the future. The 1990s witnessed the approval of additional majors for the College of Commerce and a period of mourning and transition as Dr. Ismael Yanga Sr. passed away in 1993. Dr. Marciano D. Yanga assumed the mantle, steering DYFBC towards new horizons.",
                            "Amid this transition, the academy's academic tapestry was enriched. New degree programs, including Marine Transportation and Marine Engineering, were introduced in 1994. The institution spread its wings further, obtaining Government Recognition for various programs, ranging from Computer Secretarial to Education. The early 2000s were marked by transformative milestones, including ISO certification, renaming to Dr. Yanga's Colleges, Inc. (DYCI), and the introduction of innovative programs."
                        ]
                    },
                    {
                        year: "2020s",
                        subtitle: "Adaptation and Recognition",
                        description: [
                            "The 2020s brought both unprecedented challenges and remarkable achievements. In the face of the global pandemic, Dr. Yanga’s Colleges, Inc. (DYCI) demonstrated its resilience and adaptability with the launch of the REIMAGINED Learning Program in 2020, ensuring that education continued to flourish even in the most trying times. At the WRO 2020X - Canada (Online) competition, DYCI Primes secured 2nd Place in the Open Category - Junior High division, while DYCI Novus achieved 1st Place in the Senior High division. This success continued at PRO 2021 and WRO 2021, with DYCI Primes again winning 1st Place in the Junior High division and DYCI Novus finishing 4th at WRO 2021 in Germany.",
                            "Moreover, DYCI Primes 3.0 placed 8th in the Future Engineers category at WRO 2021 and received accolades in the FIRST Grand Global Challenge Award (Education) and the FIRST Global Discover and Recover Award. The opening of the Sapientia Ideation Center has provided a vital space for student engagement across various fields.",
                            "The Blue Ocean Team captured the Champion's Award at FLL 2022, while DYCI Primes secured 1st Place at PRO 2022 and 3rd Place in the Future Innovators - Junior category at WRO Germany. In March 2023, the College Department attained ISO 9001:2015 Certification, further underscoring DYCI's commitment to quality education. These accomplishments reflect DYCI’s dedication to excellence and innovation in education."
                        ]
                    }
                ]
            }
        ],
        graduateAttributes: [
            {
                title: "Institutional Graduate Attributes",
                description: "",
                list: [
                    "Ability to demonstrate DYCIan core values: faith; wisdom; truth; love of country; charity; and excellence",
                    "Ability to uphold professional values and ethics",
                    "Ability to apply current and emerging knowledge in practice",
                    "Capacity to create/develop new knowledge and solutions to social development problems in his/her field of engagement",
                    "Ability to conceptualize, plan, and organize research and investigations in solving problems and generating solutions",
                    "Ability to demonstrate respect for diversity of people and culture",
                    "Ability to build partnerships and working relationships with diverse groups and/or inter-professional teams",
                    "Ability to communicate professionally and effectively in influencing and leading people and organizations",
                    "Ability to demonstrate and use knowledge and disciplinary expertise in professional practice",
                    "Capacity to pursue continuous learning, development, and personal growth independently",
                ]
            }
        ],
        contactUs: [
            {
                title: "General Contact Information",
                description: "",
                contactList: [
                    {
                        title: "basic-ed",
                        list: [
                            {
                                department: "PreSchool and Grade School",
                                tag: "pgs",
                                email: "dyci.gs@gmail.com",
                                url: "https://www.facebook.com/YangaGSD",
                                phone: "(044) 692 6750"
                            },
                            {
                                department: "Junior High School",
                                tag: "jhs",
                                email: "dyci.hs@gmail.com",
                                url: "https://www.facebook.com/@YangaJHS",
                                phone: "(044) 692 6750"
                            },
                            {
                                department: "Senior High School",
                                tag: "shs",
                                email: "dyci.hs@gmail.com",
                                url: "https://www.facebook.com/YangaSHS",
                                phone: "(044) 692 6750"
                            }
                        ]
                    },
                    {
                        title: "college",
                        list: [
                            {
                                department: "College of Education",
                                tag: "coed",
                                email: "coed.department@dyci.edu.ph",
                                url: "https://www.facebook.com/coed.dyci",
                                phone: ""
                            },
                            {
                                department: "College of Hospitality Management and Tourism",
                                tag: "chmt",
                                email: "",
                                url: "https://www.facebook.com/chmt.dyci",
                                phone: ""
                            },
                            {
                                department: "College of Art and Sciences",
                                tag: "cas",
                                email: "dyci.cas2019@gmail.com",
                                url: "https://www.facebook.com/dycicas",
                                phone: ""
                            },
                            {
                                department: "College of Maritime Education",
                                tag: "cme",
                                email: "",
                                url: "https://www.facebook.com/dyci.maritime",
                                phone: "(+639)966-847-5012 (Rey)"   
                            },
                            {
                                department: "College of Computer Studies",
                                tag: "ccs",
                                email: "",
                                url: "https://www.facebook.com/dyciccs",
                                phone: ""   
                            },
                            {
                                department: "College of Health and Sciences",
                                tag: "chs",
                                email: "chs.department@dyci.edu.ph",
                                url: "https://www.facebook.com/dyci.chs",
                                phone: ""   
                            },
                            {
                                department: "School of Psychology",
                                tag: "sop",
                                email: "",
                                url: "https://www.facebook.com/profile.php?id=61550471671070",
                                phone: "(+639)936-761-4896"   
                            },
                            {
                                department: "School of Mechanical Engineering",
                                tag: "sme",
                                email: "psmedyci@gmail.com",
                                url: "https://www.facebook.com/dycisme",
                                phone: ""   
                            },
                            {
                                department: "College of Accountancy",
                                tag: "coa",
                                email: "coa.department@dyci.edu.ph",
                                url: "https://www.facebook.com/dyci.coa",
                                phone: ""   
                            },
                            {
                                department: "College of Business Administration",
                                tag: "cba",
                                email: "",
                                url: "https://www.facebook.com/@dycicba/",
                                phone: ""   
                            }
                        ]
                    }
                ]
            }
        ],
        robotics: [
            {
                title: "DYCI Robotics Team",
                description: "Since 2007, the Robotics Team of Dr. Yanga’s Colleges, Inc. have been joining national robotics competitions such as Philippine Robotics Olympiad and FIRST Lego League (FLL) Philippines. The team also represents the country in international competitions such as the World Robot Olympiad, FLL World Festival, and FLL Invitational. Listed below are the achievements of the school’s robotics team."
            }
        ],
        newsletters: [
            {
                title: "DYCIan News",
                description: "We are excited to share with you the milestones that continue to define each DYCIan. Together, we strive to shape a brighter future for our students, faculty, and the entire community."
            }
        ],
    },
    affiliations: {
        home: {
            title: "Affiliations",
            data: [
                {
                    name: "Department of Education",
                    logo: "./affiliated-logo/depEd-logo.webp",
                    width: "170px",
                    description: ""
                    // alt: ""
                },
                {
                    name: "Commission on Higher Education",
                    logo: "./affiliated-logo/ched-logo.png",
                    width: "140px",
                    description: ""
                    // alt: "Commission on Higher Education"
                },
                {
                    name: "PACUCOA",
                    logo: "./affiliated-logo/pacucoa-logo.png",
                    width: "140px",
                    description: ""
                    // alt: ""
                },
                {
                    name: "PEAC",
                    logo: "./affiliated-logo/peac-logo.png",
                    width: "180px",
                    description: ""
                    // alt: ""
                },
                {
                    name: "ISO Certified Management System",
                    logo: "./affiliated-logo/iso1.png",
                    width: "230px",
                    description: ""
                    // alt: ""
                },
                {
                    name: "ISO Management System",
                    logo: "./affiliated-logo/iso2.png",
                    width: "230px",
                    description: ""
                    // alt: ""
                }
            ]
        },
        robotics: {
            title: "Affiliations and Competitions Joined",
            data: [
                {
                    name: "Philippine Robotics Olympiad™",
                    logo: "./affiliated-logo/pro.jpeg",
                    width: "180px",
                    description: [
                        "Philippine Robotics Olympiad is the longest , largest and most prestigious Robotics event in the Philippines with over 3,000 participants nationwide organized by FELTA Multi-Media Inc.",
                        "The Philippine Robotics Olympiad is an annual science and technology event to showcase the brilliance of learners in Engineering, Programming, STEM and Artificial Intelligence through Robotics Technology."
                    ],
                    link: "https://www.facebook.com/philippineroboticsolympiad/"
                },
                {
                    name: "World Robot Olympiad™",
                    logo: "./affiliated-logo/wro.webp",
                    width: "",
                    description: [
                        "World Robot Olympiad™ was founded in 2004, is a a truly global robotics competition dedicated to science, technology and education. With the mission statement of the founders with:",
                        "'To bring together young people all over the world to develop their creativity, design and problem-solving skills through challenging and educational robot competitions and activities.'"
                    ],
                    link: "https://wro-association.org/"
                },
                {
                    name: "FIRST® LEGO® League",
                    logo: "./affiliated-logo/fll.png",
                    width: "",
                    description: [
                        "FIRST® LEGO® League introduces science, technology, engineering, and math (STEM) to children ages 4-16* through fun, exciting hands-on learning.",
                        "Participants gain real-world problem solving experience through a guided, global robotics program, helping today's students and teachers build a better future together."
                    ],
                    link: "https://www.firstlegoleague.org/"
                },
                {
                    name: "FIRST® Global Challenge",
                    logo: "./affiliated-logo/fgc.png",
                    width: "",
                    description: [
                        "The FIRST Global Challenge is an olympics-style, international robotics competition that takes place in a different country each year. FIRST Global invites each nation to send a team to build and program a robot to compete.",
                        "Teams work together to complete tasks in a game themed around one of the greatest challenges facing our planet, including the 14 Grand Challenges for Engineering, in an effort to foster understanding and cooperation among the youth of the world as they use their abilities to solve the world’s problems. The challenges we face as a global society need to be solved, and the next generation can meet the task — together.",
                    ],
                    link: "https://first.global/fgc/"
                },
            ],
        },
        gs: {
            data: [
                {
                    name: "",
                    logo: "",
                    // alt: ""
                }
            ]
        },
        jhs: {
            data: [
                {
                    name: "",
                    logo: "",
                    // alt: ""
                }
            ]
        },
        college: {
        }
    },
    cookieConsent: [
        {
            title: "",
            message: [
                "We use cookies to enhance your browsing experience, analyze website traffic, and personalize content. ",
                "By continuing to use our site, you agree to our Cookie Policy. You can manage your cookie preferences at any time."
            ],
            url: "/data-privacy-policy"
        }
    ],
}