import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { mainData } from '../assets/data/mainData';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);
    const consentData = mainData?.cookieConsent?.[0];

    const navigate = useNavigate();

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent && consentData) {
            setIsVisible(true);
        }
    }, [consentData]);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setIsVisible(false);
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'declined');
        setIsVisible(false);
    };

    if (!isVisible || !consentData) return null;

    return (
        <div
            className="fixed bottom-0 w-full bg-gray-100 border-t border-gray-300 px-6 py-4 flex flex-col md:flex-row items-center justify-between z-50"
            role="alert"
            aria-live="polite"
        >
            <div className="text-center md:text-left mb-4 md:mb-0">
                {consentData.message.map((msg, index) => (
                    <p key={index} className="text-sm text-gray-800 mb-1">
                        {msg}
                        {index === consentData.message.length - 1 && (
                            <button
                                onClick={() => navigate(consentData.url)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 underline ml-1 hover:text-gold"
                            >
                                Learn More
                            </button>
                        )}
                    </p>
                ))}
            </div>
            <div className="flex gap-2">
                <button
                    onClick={handleAccept}
                    className="bg-gold text-white px-4 py-2 rounded-md text-sm uppercase hover:bg-navy-blue hover:text-white transition"
                >
                    Accept
                </button>
                <button
                    onClick={handleDecline}
                    className="bg-gold text-white px-4 py-2 rounded-md text-sm uppercase hover:bg-navy-blue hover:text-white transition"
                >
                    Decline
                </button>
            </div>
        </div>
    );
};

export default CookieConsent;